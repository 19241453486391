import Logo from "@/Components/ui/Logo";
import NavLink from "@/Components/ui/NavLink";
import { PublicLinksTranslation, publicLinks } from "@/lib/helpers/nav-links";
import { cn } from "@/lib/utils";
import { t } from "i18next";
import { PropsWithChildren } from "react";
import { Link } from "@inertiajs/react";

type AuthLayoutProps = {
    reversed?: boolean;
} & PropsWithChildren;

function AuthLayout({ children, reversed = false }: AuthLayoutProps) {
    return (
        <main className="flex flex-col max-w-screen-lg min-h-screen pt-4 mx-auto lg:pt-14">
            <div
                className={cn(
                    "flex flex-col items-center justify-center flex-auto gap-4 lg:flex-row",
                    reversed && "lg:flex-row-reverse"
                )}
            >
                <div className="flex justify-center lg:flex-1">
                    <Logo className="w-48 lg:w-72" />
                </div>
                <div className="w-full px-4 md:w-[35rem]">{children}</div>
            </div>
            <div className="pt-5">
                <div className="flex justify-center gap-2 py-4">
                    {publicLinks.map((link, index) => (
                        <NavLink
                            href={link.url}
                            className="text-xs sm:text-base"
                            key={index}
                        >
                            {t(link.name as any)}
                        </NavLink>
                    ))}
                </div>
                <div className="flex justify-center px-4 py-6 border-t">
                    <p className="text-xs text-center text-foreground/80 sm:text-base">
                        {t("app.copyRight")}
                    </p>
                </div>
            </div>
        </main>
    );
}

export default AuthLayout;
